import { render, staticRenderFns } from "./ver_agente.vue?vue&type=template&id=3a1af9fe&scoped=true"
import script from "./ver_agente.vue?vue&type=script&lang=js"
export * from "./ver_agente.vue?vue&type=script&lang=js"
import style0 from "./ver_agente.vue?vue&type=style&index=0&id=3a1af9fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a1af9fe",
  null
  
)

export default component.exports