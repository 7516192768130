<template>
  <div class="chatbot">
    <div class="content-chat">
      <div
        class="header-chat"
        :style="'background-color: ' + chatbot__color__principal + ';'"
      >
        <div class="d-flex align-items-center">
          <img
            v-if="chatbot__logo"
            :style="'color: ' + chatbot__color__principal + ';'"
            class="img-perfil"
            :src="chatbot__logo"
            :alt="chatbot__nombre"
          />
          <img
            v-else
            :style="'color: ' + chatbot__color__principal + ';'"
            class="img-perfil"
            :src="'/img/brand/0-default.svg'"
            :alt="chatbot__nombre"
          />
          <div>
            <label
              class="mb-0"
              :style="'color: ' + chatbot__color__secundario + ';'"
              >{{ chatbot__nombre }}</label
            >
            <p class="text_xs">Disponible ahora</p>
          </div>
        </div>
        <div v-if="accessibility" class="btn_box">
          <span class="btn-dash btn-dash-sm mr-2">+A</span>
          <span class="btn-dash btn-dash-sm">-A</span>
        </div>
      </div>
      <div class="mensajes">
        <template v-if="!chatbot_menu">
          <div class="mensajes-left">
            <label>{{ chatbot__nombre }}</label>
            <div class="mensajes-left__item">
              <p>
                Bienvenido a Databot, estoy disponible, cuéntame en que te puedo
                ayudar?
              </p>
              <p class="text_xs">22/06/2022</p>
            </div>
          </div>
          <div class="mensajes-right">
            <label>Tu</label>
            <div
              class="mensajes-right__item"
              :style="'background-color: ' + chatbot__color__principal + ';'"
            >
              <p :style="'color: ' + chatbot__color__secundario + ';'">
                Necesito cotizar un chatbot para mi sitio web
              </p>
              <p class="text_xs">22/06/2022</p>
            </div>
          </div>
          <div class="mensajes-left">
            <label>{{ chatbot__nombre }}</label>
            <div class="mensajes-left__item">
              <p>
                ¡Genial! con gusto te ayudaré con esa información. Me gustaría
                conocerte un poco más. ¿Cuál es tu nombre?
              </p>
              <p class="text_xs">22/06/2022</p>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- <template> -->
          <div class="menu">
            <div class="mensajes-left">
              <Loading
                v-if="
                  welcomes &&
                    welcomes.length == 0 &&
                    options &&
                    options.length == 0
                "
              />
              <template v-else>
                <label>{{ chatbot__nombre }}</label>
                <div v-if="welcomes && welcomes.length > 0">
                  <div v-for="welcome in welcomes"
                      class="mensajes-left__item"
                      :key="welcome.id"
                      >
                      {{ welcome.text }}
                      <p class="text_xs">Hoy a las 11:23</p>
                  </div>
                </div>
                <div class="mensajes-left__item p-0">
                  <div class="menu">
                    <p
                      v-for="(option, index) in options"
                      :key="option.id"
                      class="menu-item mb-0"
                    >
                      {{ option.label || placeholderOptions[index] }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <!-- Recomendador -->
        <!-- <template v-else>
          <div class="d-flex justify-content-center align-items-center">
            <div class="card">
              <p>Mug Databot Modelo S</p>
              <img class="img_box" src="/img/illustrations/3-producto.png" alt="">
              <label>$4.990</label>
            </div>
          </div>
        </template> -->
      </div>
      <div class="credits">
        <img
          class="databot-iso"
          src="/img/icons/1-databot-icon.svg"
          alt="logo databot"
        />
        <span>Usamos Databot</span>
      </div>
    </div>
    <div class="burbuja">
      <div
        v-if="!bubble"
        :style="'background-color: ' + chatbot__color__principal + ';'"
        class="burbuja__icono"
      >
        <img
          src="https://cdn.discordapp.com/attachments/700052004877238285/745778899757170808/chat-databot-2.png"
          :alt="chatbot__nombre"
        />
      </div>
      <img
        class="img_bubble"
        v-else
        :src="chatbot__logo ? chatbot__logo : '/img/brand/0-default.svg'"
        :alt="chatbot__nombre"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chatbot',
  props: {
    chatbot__trama: String,
    chatbot__logo: String,
    chatbot__nombre: String,
    chatbot__color__principal: String,
    chatbot__color__secundario: String,
    chatbot_menu: Boolean,
    accessibility: Boolean,
    bubble: Boolean,
    welcomes: Array,
    options: Array,
    buttons: Array,
    placeholderMessage: Array,
    placeholderOptions: Array,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.chatbot {
  .content-chat {
    max-width: 100%;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    height: auto;
    border-radius: 1rem;
    margin: 0 auto;

    @media (min-width: 1279px) {
      width: 368px;
    }
    .header-chat {
      position: relative;
      width: 100%;
      height: 65px;
      border-radius: 1rem 1rem 0 0;
      padding: 0 1rem;

      .img-perfil {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        object-fit: cover;
        object-position: center;
        background-color: #ffffff;
        border: 3px solid;
      }
      label {
        font-size: 1rem;
      }
      .btn_box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -1rem;
        right: 1rem;

        .btn-dash-sm {
          color: #181818;
          font-size: 12px;
          padding: 0.25rem 0.5rem;
        }
      }
    }
    .mensajes {
      padding: 2rem 1rem 3rem;
      background-color: #fff;
      height: 475px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 2rem;
        transition: 0.5s;

        &:hover {
          transition: 0.5s;
          background: #cdcdcd;
        }
        &:active {
          background-color: #cdcdcd;
        }
      }

      label {
        font-size: 12px;
        color: #b2b2b2;
        margin-bottom: 0.25rem;
      }
      &-left {
        max-width: 100%;

        &__item {
          padding: 1rem;
          border-radius: 1rem 1rem 1rem 1rem;
          margin-bottom: 1rem;
          border: 1px solid #e8e8e8;
          background-color: #fafafa;

          p:not(.text_xs) {
            font-size: 14px;
            color: #181818;
          }
          .menu {
            width: 100%;

            &-item {
              padding: 0.75rem 1rem;
              border-bottom: 1px solid #e8e8e8;

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }
      }
      &-right {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0 0 1rem auto;

        &__item {
          width: fit-content;
          border: 1px solid #e8e8e8;
          background-color: #fafafa;
          border-radius: 1rem 0 1rem 1rem;
          padding: 1rem;
          margin-bottom: 0.25rem;

          p:not(.text_xs) {
            text-align: left;
            font-size: 14px;
            color: #fff;
          }
        }
      }
      // recomendador
      .card {
        width: 15em;
        padding: 1.25rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .img_box {
          width: 120px;
          height: auto;
          border: 1px solid #e8e8e8;
          background-color: #fafafa;
          padding: 1rem;
          border-radius: 0.5rem;
        }
      }
    }
    .credits {
      padding: 1rem;
      border-radius: 0 0 1rem 1rem;
      background-color: #fff;
      text-align: center;

      .databot-iso {
        width: 0.8rem;
        margin-right: 0.4rem;
      }
      span {
        font-size: 11px;
        font-weight: 700;
        color: #b2b2b2;
      }
    }
  }
  .burbuja {
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    max-width: 368px;
    margin: 2rem auto 0;

    &__icono,
    .img_bubble {
      border-radius: 100%;
      width: 65px;
      height: 65px;
      text-align: center;

      img {
        width: 3.25rem;
        margin-top: 0.5rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
</style>
