<template>
  <FadeTransition :duracion="100">
    <div v-show="showDropdown" class="multiselect">
      <div class="scroll_box">
        <label
          v-for="(item, index) in items"
          class="items mb-0"
          :key="index"
          :for="item.name"
        >
          <input
            class="m-0 mr-3"
            type="checkbox"
            v-model="selecteds"
            :value="item"
            :name="item.name"
            :id="item.name"
          >
          <p class="mb-0">{{ item.name }}</p>
        </label>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions';

export default {
  components: { FadeTransition },
  props: ["items", "showDropdown"],
  data() {
    return {
      selecteds: []
    };
  },
  watch: {
    selecteds(val) {
      this.$emit("sendCategoryFilter", val);
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  position: absolute;
  width: 100%;
  top: 14%;
  left: 0;
  right: 0;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 10px 30px 30px #DEEDFF;
  background-color: #fff;
  z-index: 100;
  
  @media (max-width: 1024px) {
    top: 18%;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: .5rem;
    transition: .25s;
    cursor: pointer;
    
    &:hover {
      transition: .25s;
      background-color: #f2f2f2;

      p {
        color: #181818;
      }
    }
  }
}
.scroll_box {
  max-height: 500px;
  overflow-y: scroll;
  /* Color de fondo y radius esquinas del thumb */
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 2rem;

    /* Cambiamos el fondo en hover */
    &:hover {
      background: #cdcdcd;
    }
    /* Cambiamos el fondo en active */
    &:active {
      background-color: #cdcdcd;
    }
  }
}
</style>