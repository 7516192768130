CODIGO

<template>
  <section class="chatbot_settings">
    <validationchat />
    <validationbot />
    <Loading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="card_box">
              <h2 class="mb-5">
                {{
                  (!showMenuSettings ? 'Configuración de ' : 'Menú de ') +
                    selected_bot.bot_name
                }}
                <p
                  v-if="showMenuSettings"
                  @click="showMenuSettings = false"
                  class="btn-text mt-1"
                >
                  <i class="fas fa-arrow-left mr-2" />Atrás
                </p>
              </h2>
              <template v-if="!showMenuSettings">
                <div class="field buefy">
                  <!-- <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label class="mr-2">Menú inicial</label>
                      <b-switch v-model="chatbot.menu" />
                    </div>
                    <p class="info">
                      Tu chatbot puede iniciar desplegando un menú configurado a
                      medida.
                    </p> -->
                  <!-- <span
                      v-if="chatbot.menu && !loadigDialogNodes"
                      class="btn-text destacado d-block mt-3 mb-0"
                      @click="showMenuSettings = true"
                      >Editar Menú<i class="fas fa-arrow-right ml-2"
                    /></span> -->

                  <!-- <span
                      class="btn-text destacado d-block mt-3 mb-0"
                      v-if="chatbot.menu"
                      @click="showMenuSettings = true"
                      >Editar Menú <i class="fas fa-arrow-right ml-2"
                    /></span>
                  </div> -->

                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label class="mr-2"
                        >¿Desea que este chatbot envíe notificaciones por
                        correo?</label
                      >
                      <b-switch v-model="chatbot.notification" />
                    </div>
                    <p class="info">
                      El chatbot enviará notificaciones por correo a todos los
                      administradores y agentes cuando se registra un nuevo
                      Lead.
                    </p>
                  </div>

                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label class="mr-2"
                        >Activar opciones de accesibilidad</label
                      >
                      <b-switch v-model="chatbot.accessibility" />
                    </div>
                    <p class="info">
                      Los usuarios podrán modificar el tamaño de la letra de los
                      mensajes en el chatbot para facilitar su lectura.
                    </p>
                  </div>

                  <div class="two_box two_box-lg my-4">
                    <div class="info_box">
                      <div class="label-box">
                        <label class="mr-2"
                          >¿Persistencia de conversación?</label
                        >
                        <b-switch v-model="chatbot.has_persistence" />
                      </div>
                      <p class="info mb-0">
                        Recuerda conversaciones con usuarios que interactuaron
                        antes.
                      </p>
                    </div>
                    <template v-if="plan_name == 'Plan Basic-web0'">
                      <router-link
                        :to="{
                          name: 'dashboard-planes',
                          query: {
                            seccion: 'mejorar-plan',
                            bot_id: selected_bot.id,
                          },
                        }"
                      >
                        <div class="info_box">
                          <div class="label-box">
                            <label class="text-disabled mr-2"
                              >¿Utilizar imagen de perfil en la burbuja de la
                              ventana?</label
                            >
                            <b-switch disabled />
                          </div>
                          <p class="info text-disabled mb-0">
                            ¡Tu imagen de marca se verá en todo momento!
                          </p>
                          <span class="upgrade-label">Mejorar plan</span>
                        </div>
                      </router-link>
                    </template>
                    <template v-else>
                      <div class="info_box">
                        <div class="label-box">
                          <label class="mr-2"
                            >¿Utilizar imagen de perfil en la burbuja de la
                            ventana?</label
                          >
                          <b-switch v-model="chatbot.img_bubble" />
                        </div>
                        <p class="info mb-0">
                          ¡Tu imagen de marca se verá en todo momento!
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <hr class="my-5" />
                <label class="mb-4">Mostrar chatbot en:</label>
                <div class="check__content">
                  <b-checkbox class="bajada" v-model="chatbot.desktop"
                    >Escritorio
                    <img
                      class="desktop_icon"
                      src="/img/icons/widgetWhatsapp/3.1-desktop.svg"
                      alt="Escritorio"
                    />
                  </b-checkbox>
                  <b-checkbox class="bajada" v-model="chatbot.mobile"
                    >Móvil
                    <img
                      class="mobile_icon"
                      src="/img/icons/widgetWhatsapp/3.2-mobile.svg"
                      alt="movil"
                    />
                  </b-checkbox>
                </div>
                <hr class="my-5" />
                <label>¿Abrir ventana automáticamente en escritorio?</label>
                <select v-model="chatbot.init_conver" placeholder="Seleccionar">
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si (Recomendado)</option>
                  <option :value="false">No</option>
                </select>
                <label>¿Abrir ventana automáticamente en mobile? </label>
                <select
                  v-model="chatbot.init_conver_mobile"
                  placeholder="Seleccionar"
                >
                  <option :value="null">Seleccionar</option>
                  <option :value="true">Si (Recomendado)</option>
                  <option :value="false">No</option>
                </select>
                <hr class="my-5" />
                <label>Horario de contacto</label>
                <div class="card mb-3">
                  <div
                    v-for="(schedule, scheduleIndex) in schedules"
                    :key="scheduleIndex"
                  >
                    <transition name="fade">
                      <div v-if="schedule.showValues">
                        <div class="checks_box">
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Lu"
                          >
                            Lu
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Ma"
                          >
                            Ma
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Mi"
                          >
                            Mi
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Ju"
                          >
                            Ju
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Vi"
                          >
                            Vi
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Sá"
                          >
                            Sá
                          </b-checkbox>
                          <b-checkbox
                            class="check_time"
                            v-model="schedule.days"
                            native-value="Do"
                          >
                            Do
                          </b-checkbox>
                        </div>
                        <br />
                        <div
                          class="d-flex align-items-center mb-4"
                          v-if="schedule.showValues"
                        >
                          <div class="col-md-6 pl-0">
                            <p class="etiqueta">Desde:</p>
                            <input
                              v-model="schedule.hourFrom"
                              type="time"
                              value=""
                              class="mb-0"
                            />
                          </div>
                          <div class="col-md-6 pr-0">
                            <p class="etiqueta">Hasta:</p>
                            <input
                              v-model="schedule.hourTo"
                              type="time"
                              value=""
                              class="mb-0"
                            />
                          </div>
                        </div>
                      </div>
                    </transition>
                    <div class="d-flex">
                      <!-- <input
                          type="text"
                          :value="[
                            schedule.days.join(', ') + ' - ' +
                            schedule.hourFrom + ' - ' + schedule.hourTo,
                          ]"
                          @click="schedule.showValues = !schedule.showValues"
                        /> -->
                      <div class="preview_box">
                        <p
                          class="text-preview"
                          @click="schedule.showValues = !schedule.showValues"
                        >
                          {{
                            schedule.days.join(', ') +
                              ' - ' +
                              schedule.hourFrom +
                              ' - ' +
                              schedule.hourTo
                          }}
                        </p>
                        <i
                          v-if="schedule.showValues"
                          class="fas fa-eye-slash"
                        ></i>
                        <i v-if="!schedule.showValues" class="fas fa-eye" />
                      </div>
                      <div
                        class="delete_box"
                        @click="deleteSchedule(scheduleIndex)"
                      >
                        <i
                          @click="deleteSchedule(scheduleIndex)"
                          class="fas fa-trash-alt"
                        ></i>
                      </div>
                    </div>
                    <!-- <input
                        class="input_static"
                        type="text"
                        :value="schedule.days.join(', ')"
                        disabled
                      /> -->
                    <hr
                      class="mt-3 mb-4"
                      :class="schedules.length == 6 ? 'not_here' : ''"
                      v-if="schedules.length >= 1"
                    />
                  </div>
                  <p
                    class="btn-text my-0"
                    :class="schedules.length == 6 ? 'not_here' : ''"
                    @click="addSchedule()"
                  >
                    <i class="fas fa-plus" /> Agregar horario
                  </p>
                </div>
                <label>Sitio web</label>
                <input
                  type="text"
                  required
                  v-model="chatbot.web"
                  name="web"
                  placeholder="www.ejemplo.cl"
                />
                <p v-if="error.web" class="text_error">
                  Ingresar una página web para el chatbot
                </p>
              </template>
              <template v-else>
                <div>
                  <label>Mensaje de bienvenida</label>
                  <div
                    v-for="(message, index) in welcomeVariants"
                    :key="message.id"
                    class="delete-input"
                  >
                    <input
                      type="text"
                      :class="{
                        input_error:
                          message.text == 0 && error.empty == 'empty_field',
                      }"
                      v-model="message.text"
                      :placeholder="placeholderMessage[index]"
                    />
                    <button
                      v-if="welcomeVariants && welcomeVariants.length > 1"
                      @click="deleteWelcomeVariation(index)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </div>
                  <button
                    @click="addWelcomeVariant()"
                    class="btn btn-dash btn-dash-sm m-0 mt-2"
                    v-if="welcomeVariants.length < 3"
                  >
                    +
                  </button>
                  <label class="mt-5">Opciones del menú</label>
                  <div v-for="(option, index) in menuOptions" :key="option.id">
                    <p
                      class="text_error"
                      v-if="option.label == 0 && error.empty == 'empty_field'"
                    >
                      Completa esta opción
                    </p>
                    <div v-else class="py-3">
                      <div
                        class="cart-label-top delete-input delete-input-option"
                      >
                        <label class="mt-1 label-cart"
                          >Opción {{ index + 1 }}</label
                        >
                        <button @click="deteleOption(index)">
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                      <div class="row options-row pt-3 pb-2 activate-select">
                        <div class="d-flex flex-column col-lg-6">
                          <div>
                            <input
                              type="text"
                              v-model="option.label"
                              :placeholder="placeholderOptions[index]"
                              :class="{
                                input_error:
                                  option.label == 0 &&
                                  error.empty == 'empty_field',
                              }"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div>
                            <select class="col-12" v-model="option.action">
                              <option value="" disabled selected
                                >Tipo de botón</option
                              >
                              <option
                                v-for="(botton, index) in optionButton"
                                :key="index"
                                :value="botton"
                                >{{ getButtonTypeName(botton) }}</option
                              >
                            </select>
                          </div>
                        </div>
                        <!-- Mostrar el segundo select si 'answer' está seleccionado -->
                        <div class="col-lg-12">
                          <select
                            v-model="option.selectedIntent"
                            v-if="option.action === 'answer'"
                          >
                            <option value="" disabled selected
                              >Seleccionar intent</option
                            >
                            <option
                              :value="intent"
                              v-for="intent in intents"
                              :key="intent.id"
                            >
                              {{ intent.intent_ui_name }}
                            </option>
                          </select>
                        </div>
                        <!-- Mostrar el segundo menu si 'buttons' está seleccionado -->
                        <div
                          class="col-12 pb-2"
                          v-if="option.action === 'buttons'"
                        >
                          <label class="suboptionMenu">Opciones botón</label>
                          <div
                            v-for="(option, subindex) in option.options"
                            :key="option.id"
                          >
                            <p
                              class="text_error"
                              v-if="
                                option.label == 0 &&
                                  error.empty == 'empty_field'
                              "
                            >
                              Completa esta opción
                            </p>
                            <div v-else class="py-2">
                              <div
                                class="cart-label-top delete-input delete-input-option"
                              >
                                <label class="mt-1 label-cart"
                                  >Opción
                                  {{ `${index + 1}.${subindex + 1}` }}</label
                                >
                                <button @click="deletesubOption(index)">
                                  <i class="fas fa-trash-alt" />
                                </button>
                              </div>
                              <div
                                class="row options-row pt-3 pb-2 activate-select"
                              >
                                <div class="d-flex flex-column col-lg-6">
                                  <div>
                                    <input
                                      type="text"
                                      v-model="option.label"
                                      :placeholder="placeholderOptions[index]"
                                      :class="{
                                        input_error:
                                          option.label == 0 &&
                                          error.empty == 'empty_field',
                                      }"
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div>
                                    <select
                                      class="col-12"
                                      v-model="option.action"
                                    >
                                      <option value="" disabled selected
                                        >Tipo de botón</option
                                      >
                                      <option
                                        v-for="(botton,
                                        index) in suboptionButton"
                                        :key="index"
                                        :value="botton"
                                        >{{ getButtonTypeName(botton) }}</option
                                      >
                                    </select>
                                  </div>
                                </div>
                                <!-- Mostrar el segundo select si 'answer' está seleccionado -->
                                <div class="col-lg-12">
                                  <select
                                    v-model="option.selectedIntent"
                                    v-if="option.action === 'answer'"
                                  >
                                    <option value="" disabled selected
                                      >Seleccionar intent</option
                                    >
                                    <option
                                      :value="intent"
                                      v-for="intent in intents"
                                      :key="intent.id"
                                    >
                                      {{ intent.intent_ui_name }}
                                    </option>
                                  </select>
                                </div>
                                <!-- Mostrar el input si 'url' está seleccionado -->
                                <div class="col-lg-12">
                                  <input
                                    v-if="option.action === 'url'"
                                    type="text"
                                    class="col-lg-12"
                                    placeholder="Ingresar URL"
                                    v-model="option.url"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <button
                            @click="addSubOptions(option)"
                            v-if="option.options.length < 4"
                            class="btn btn-dash btn-dash-sm m-0 mt-2"
                          >
                            +
                          </button>
                        </div>
                        <!-- Mostrar el input si 'url' está seleccionado -->
                        <div class="col-lg-12">
                          <input
                            v-if="option.action === 'url'"
                            type="text"
                            class="col-lg-12"
                            placeholder="Ingresar url"
                            v-model="option.url"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    @click="addOptions()"
                    v-if="menuOptions.length < 6"
                    class="btn btn-dash btn-dash-sm m-0 mt-2"
                  >
                    +
                  </button>
                </div>
              </template>
              <div
                class="d-flex justify-content-between align-items-center my-5"
              >
                <button
                  class="btn btn-base"
                  type="submit"
                  :disabled="loading"
                  @click.prevent="save_bot"
                >
                  Guardar
                </button>
                <button
                  v-show="chatbot.activated"
                  class="btn btn-base outline"
                  @click="show = true"
                >
                  Implementar
                </button>
              </div>
              <router-link class="btn-text" to="/dashboard"
                ><i class="fas fa-arrow-left mr-2" />Volver</router-link
              >
            </div>
          </div>
          <!-- Columna preview -->
          <div class="col-lg-5 p-0">
            <div class="card card-chat pt-0">
              <div class="card-body">
                {{ chatbot.trama_url }}
                <Chatbot
                  :chatbot__trama="chatbot.trama_url"
                  :chatbot__logo="chatbot.bot_logo_url"
                  :chatbot__nombre="chatbot.bot_name"
                  :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
                  :chatbot__color__secundario="chatbot.bot_title_color"
                  :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
                  :chatbot__bot__color__letra="chatbot.bot_box_font_color"
                  :chatbot__user__color__fondo="chatbot.user_box_bg_color"
                  :chatbot__user__color__letra="chatbot.user_box_font_color"
                  :accessibility="chatbot.accessibility"
                  :bubble="chatbot.img_bubble"
                  :chatbot_menu="false"
                  :welcomes="welcomeVariants"
                  :options="menuOptions"
                  :buttons="submenuOptions"
                  :placeholderMessage="placeholderMessage"
                  :placeholderOptions="placeholderOptions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Implementacion
        :show="show"
        :bot_id="chatbot.id"
        :bot_token="chatbot.token"
        @close="show = false"
      />
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapMutations, mapState } from 'vuex';
import validationchat from './components/validationchat.vue';
import validationbot from './components/validationbot.vue';
import Implementacion from '@/components/Implementacion.vue';
import dashboard_api from '@/dashboard_api.js';
import Chatbot from '@/components/Chatbot.vue';
import { reverseString } from '@/utils/utils';

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default {
  components: {
    Implementacion,
    Chatbot,
    validationchat,
    validationbot,
  },
  data() {
    return {
      loadigDialogNodes: false,
      menuOptions: [
        {
          label: '',
          action: '',
          value: {
            input: {
              text: '',
            },
          },
        },
      ],
      submenuOptions: [],
      welcomeVariants: [
        {
          text: '',
        },
      ],
      welcomeNode: {},
      showMenuSettings: false,
      loading: false,
      show: false,
      chatbot: {
        id: '',
        name: '',
        bot_logo_url: '',
        trama_url: '',
        bot_title: '',
        user_box_bg_color: '#ffffff',
        user_box_font_color: '#525252',
        bot_box_bg_color: '#D8EEFF',
        bot_box_font_color: '#ffffff',
        bot_title_bar_bg_color: '#333',
        bot_title_color: '#ffffff',
        bot_name: '',
        option_bg_color: '#ffffff',
        option_font_color: '#525252',
        web: '',
        rolagente: [],
        template: '',
        type_bot: false,
        notification: false,
        message_end: '',
        accessibility: false,
        img_bubble: false,
        menu: false,
        desktop: false,
        mobile: false,
        init_conver: true,
        init_conver_mobile: true,
      },
      business_type: [],
      error: {
        name: '',
        web: '',
        empty: '',
      },
      custom: '',
      schedules: [],
      idSchedulesToDelete: [],
      showSchedule: true,
      // placeholderMessage: [
      //   'Bienvenido, estoy en línea, ¿Cómo puedo ayudarte?',
      //   '¡Hola!, estoy en línea, ¿En qué te puedo ayuda?',
      //   '¡Bienvenido!, estoy en línea, ¿En qué necesitas ayuda?',
      // ],
      // optionButton: ['answer', 'buttons', 'url'],
      // suboptionButton: ['answer', 'url'],
      // placeholderOptions: [
      //   '🔩 Opciones',
      //   '🔥 Opciones',
      //   '🛠️ Opciones',
      //   '⚙️ Opciones',
      //   '🧰 Opciones',
      //   '🪛 Opciones',
      //   '📍 Opciones',
      //   '🧑‍💻 Opciones',
      // ],
      intents: [],
    };
  },
  created() {
    window.scrollTo(0, 0);

    dashboard_api.get('/business_types/all_not_custom').then(response => {
      this.business_type = response.data;
    });
    console.log('selected_bot', this.selected_bot);
  },
  mounted() {
    this.initializeInitialMenu();
  },

  computed: {
    ...mapState(['user', 'selected_bot', 'ownerBots', 'plan_name']),

    ...mapState('dialogNodesModule', ['dialogNodes']),

    bot_id() {
      return this.selected_bot.id;
    },
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getChatbotEdit();
        this.getDialogNodes(val);
      },
    },
  },

  methods: {
    ...mapMutations(['editSelectedBot']),
    async initializeInitialMenu() {
      // get welcome texts
      const WELCOME_INTENT_NAME = 'INICIO'; // forever, dont change
      const welcomeNode = await this.$store.dispatch(
        'dtClientNodesModule/listByIntentName',
        { intentName: WELCOME_INTENT_NAME, botId: this.selected_bot.id },
      );
      this.welcomeVariants = welcomeNode.output.generic[0].values;
      // get buttons
      const WELCOME_INTENT_BUTTONS_NAME = 'MENÚ'; // forever, dont change
      const welcomeNodeButtons = await this.$store.dispatch(
        'dtClientNodesModule/listByIntentName',
        {
          intentName: WELCOME_INTENT_BUTTONS_NAME,
          botId: this.selected_bot.id,
        },
      );
      this.menuOptions = welcomeNodeButtons.output.generic[0].options;
      console.log('🚀 Aqui *** -> welcomeNodeButtons:', welcomeNodeButtons);
      // adding submenuOptions
      this.menuOptions = this.menuOptions.map(el => ({
        ...el,
        options: [],
      }));
      // getting intents
      this.intents = await this.$store.dispatch(
        'dtClientIntentsModule/listByBotId',
        this.selected_bot.id,
      );
      // filter only intents which start with FAQ. and CUSTOM.
      this.intents = this.intents.filter(
        intent =>
          intent.intent_name.startsWith('FAQ.') ||
          intent.intent_name.startsWith('CUSTOM.'),
      );
    },
    addOptions() {
      this.menuOptions.push({
        label: '',
        action: '',
        value: {
          input: {
            text: '',
          },
        },
      });
    },

    addSubOptions(option) {
      option.options.push({
        label: '',
        action: '',
        value: {
          input: {
            text: '',
          },
        },
      });
    },

    addWelcomeVariant() {
      this.welcomeVariants.push({
        text: '',
      });
    },

    deletesubOption(index) {
      this.$swal({
        title: '¿Eliminar sub opción?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async resp => {
        if (resp.isConfirmed) {
          this.submenuOptions.splice(index, 1);
        }
      });
    },

    deteleOption(index) {
      // console.log('index option', index);
      // this.$swal({
      //   title: '¿Eliminar opción?',
      //   icon: 'warning',
      //   showConfirmButton: true,
      //   confirmButtonText: 'Eliminar',
      //   showCancelButton: true,
      //   cancelButtonText: 'Cancelar',
      // }).then(async resp => {
      //   if (resp.isConfirmed) {}
      // });
      this.menuOptions.splice(index, 1);
    },

    deleteWelcomeVariation(index) {
      this.$swal({
        title: '¿Eliminar variación?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async resp => {
        if (resp.isConfirmed) {
          this.welcomeVariants.splice(index, 1);
        }
      });
    },
    getButtonTypeName(type) {
      switch (type) {
        case 'answer':
          return 'Respuesta';
        case 'buttons':
          return 'Botones';
        case 'url':
          return 'URL';
        default:
          return 'Tipo de botón';
      }
    },

    async getDialogNodes(bot) {
      this.loadigDialogNodes = true;

      console.log('entro a dialog node');
      console.log('bot', bot);

      if (bot.business_type && bot.business_type.assistant_id.length > 0) {
        console.log('entro a dialog node 2');

        await this.$store.dispatch(
          'dialogNodesModule/list',
          reverseString(bot.business_type.assistant_id),
        );

        if (this.dialogNodes.length > 0) {
          this.welcomeNode = this.dialogNodes.find(
            el => el.dialog_node == 'Bienvenido',
          );
          console.log('welcomeNode1', this.welcomeNode);

          this.welcomeVariants = this.welcomeNode.output.generic[0].values;
          this.menuOptions = this.welcomeNode.output.generic[1].options;
        }
      }
      this.loadigDialogNodes = false;
    },

    async getChatbotEdit() {
      let self = this;
      self.chatbot = (
        await dashboard_api.get(`/bot/showedit/${self.bot_id}`)
      ).data;

      const businessType = (
        await dashboard_api.get(`/get_bot_info/${self.chatbot.id}`)
      ).data.business_type;
      self.custom = businessType.show;
      // inicializando horarios comerciales
      if (self.showSchedule) {
        dashboard_api.get(`schedule/list/${self.chatbot.id}`).then(res => {
          for (const schedule of res.data) {
            const formatted = self.formatSchedule(schedule);
            self.addSchedule(
              schedule.id,
              formatted.days,
              formatted.hourFrom,
              formatted.hourTo,
            );
          }
        });
      }
    },

    formatSchedule(scheduleFromDB) {
      const weekDays = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      if (typeof scheduleFromDB.days === 'string') {
        scheduleFromDB.days = JSON.parse(scheduleFromDB.days);
      }
      const days = scheduleFromDB.days.map(day => weekDays[day]);
      const hourFrom = scheduleFromDB.range.split('-')[0];
      const hourTo = scheduleFromDB.range.split('-')[1];
      return { days, hourFrom, hourTo };
    },
    deleteSchedule(index) {
      console.log('schedules', this.schedules);
      console.log('index', index);
      if (this.schedules[index].id) {
        this.idSchedulesToDelete.push(this.schedules[index].id);
      }
      this.schedules.splice(index, 1);
    },
    addSchedule(id = null, days = [], hourFrom = '', hourTo = '') {
      if (this.schedules.length >= 6) return; // validacion de cantidad de horarios
      const newSchedule = {
        id,
        days,
        hourFrom,
        hourTo,
        showValues: !id,
      };
      this.schedules.push(JSON.parse(JSON.stringify(newSchedule)));
    },
    updateScheduleSkill() {
      // Darle formato al array a enviar
      const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
      const schedules = JSON.parse(JSON.stringify(this.schedules));

      // clonando horarios para no modificar original
      for (const schedule of schedules) {
        schedule.days = schedule.days.map(day => days.indexOf(day));
        schedule.range = `${schedule.hourFrom}-${schedule.hourTo}`;
      }
      const businessHours = schedules.filter((schedule, index) => {
        if (schedule.days.length > 0 && schedule.hourFrom && schedule.hourTo) {
          return true;
        }

        this.schedules.splice(index, 1);
        return false;
      });
      // actualizando horario en base de datos
      businessHours.forEach((businessHour, index) => {
        if (businessHour.id) {
          // actualizar registro
          dashboard_api
            .put(`schedule/put/${businessHour.id}`, {
              days: businessHour.days,
              range: businessHour.range,
              timezone: TIMEZONE,
            })
            .then(response => {});
        } else {
          // crear nuevo registro
          dashboard_api
            .post('schedule/newschedule', {
              days: businessHour.days,
              range: businessHour.range,
              bot_id: this.chatbot.id,
              timezone: TIMEZONE,
            })
            .then(response => {
              this.schedules.splice(index, 1, {
                id: response.data.id,
                showValues: false,
                ...this.formatSchedule(response.data),
              });
            });
        }
      });

      // eliminando horarios
      Promise.all(
        this.idSchedulesToDelete.map(scheduleId =>
          dashboard_api.delete(`schedules/${scheduleId}`),
        ),
      ).then(() => {
        this.idSchedulesToDelete = [];
      });
    },
    save_bot() {
      // in case of save initial menu
      if (this.showMenuSettings) {
        this.saveInitialMenu();
        return;
      }
      const self = this;
      self.$swal.showLoading();

      // validando assistant ID rasa
      if (
        self.selected_bot.business_type &&
        self.selected_bot.business_type.assistant_id.length > 0
      ) {
        // Validando menu campos vacios
        try {
          let emptyWelcomeVariant = self.welcomeVariants.filter(
            el => el.text.length == 0,
          );

          let empty = self.menuOptions.filter(el => el.label.length == 0);

          if (emptyWelcomeVariant.length > 0) {
            self.error.empty = 'empty_field';

            self.$swal({
              icon: 'error',
              title: 'Completa los campos faltantes',
            });
          } else if (empty.length > 0) {
            self.error.empty = 'empty_field';

            self.$swal({
              icon: 'error',
              title: 'Completa los campos faltantes',
            });
          } else {
            self.welcomeVariants.forEach(el => {
              el.text;
            });

            self.menuOptions.forEach(el => {
              el.label;
              el.value.input.text = el.label;
            });

            self.$store.dispatch('dialogNodesModule/update', {
              assistant_id: reverseString(
                self.selected_bot.business_type.assistant_id,
              ),
              id: self.welcomeNode._id,
              data: self.welcomeNode,
            });

            console.log('welcomeNode2', self.welcomeNode);

            dashboard_api
              .put(`/bot/${self.chatbot.id}`, {
                chatbot: self.chatbot,
              })
              .then(async response => {
                self.editSelectedBot(response.data);
                await self.getDialogNodes(self.selected_bot);

                self.$swal({
                  icon: 'success',
                  title: 'El chatbot se actualizó exitosamente',
                });
              });
            // actualizar horario comercial en skill del bot
            if (self.showSchedule) {
              self.updateScheduleSkill();
            }
            self.error.empty = '';
          }
        } catch (error) {
          console.log('error->', error);
        }
      }
    },
    async saveInitialMenu() {
      // saving texts
      await this.$store.dispatch('dtClientNodesModule/updateWelcomeTexts', {
        answers: this.welcomeVariants.map(el => el.text),
        bot_id: this.selected_bot.id,
        buttons: this.menuOptions.map(el => ({
          text: el.label,
          type: el.action,
        })),
      });
      const formattedOptions = await this.formatInitialMenuOptions(
        this.menuOptions,
      );
      await this.$store.dispatch('dtClientNodesModule/updateWelcomeButtons', {
        buttons: formattedOptions,
        bot_id: this.selected_bot.id,
      });
    },

    async formatInitialMenuOptions(options) {
      const buttonButtons = options.filter(el => el.action === 'buttons');
      const promisesButtonButtons = buttonButtons.map(async buttonButton => {
        buttonButton.options = await this.formatInitialMenuOptions(
          buttonButton.options,
        );
        return buttonButton;
      });

      await Promise.all(promisesButtonButtons);
      const answerButtons = options.filter(el => el.action === 'answer');
      // get nodes by intent name for answerButtons
      const promises = await Promise.all(
        answerButtons.map((button, index) =>
          this.$store.dispatch('dtClientNodesModule/listByIntentName', {
            intentName: button.selectedIntent.intent_name,
            botId: this.selected_bot.id,
          }),
        ),
      );
      // asign each promise to each button
      answerButtons.forEach((button, index) => {
        if (promises[index].id) {
          button.jump_to = promises[index].id;
        }
      });
      // last format to buttons
      options.forEach((option, index) => {
        console.log('Opcion: ', option);
        if (option.action === 'answer') {
          options[index] = {
            text: option.label,
            action: option.action,
            jump_to: option.jump_to,
          };
        }
        if (option.action === 'buttons') {
          options[index] = {
            text: option.label,
            action: option.action,
            options: option.options,
          };
        }
        if (option.action === 'url') {
          options[index] = {
            text: option.label,
            action: option.action,
            url: option.url,
          };
        }
      });

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot_settings {
  // padding: 6rem 0;

  .preview_box {
    width: 100%;
    position: relative;

    .fa-eye-slash,
    .fa-eye {
      position: absolute;
      color: #767676;
      font-size: 1rem;
      opacity: 0;
      top: 15px;
      right: 15px;
      // transition: .8s;
    }
    .text-preview {
      width: 100%;
      padding: 0.75rem;
      background-color: #f2f2f2;
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      &:hover ~ .fa-eye-slash {
        opacity: 1 !important;
      }
      &:hover ~ .fa-eye {
        opacity: 1 !important;
      }
    }
  }
  .delete_box {
    padding: 0.75rem;
    width: 2.5rem;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #e8e8e8;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
    .fa-trash-alt {
      color: #767676;
      margin-bottom: 0;
      transition: 0.5s;
    }
  }
  .etiqueta {
    margin-bottom: 0;
  }
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5,
  .col-lg-6 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 0 0 3rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
  .temas {
    margin-top: 2rem;

    &__item {
      .color_box {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input {
          width: 50px;
          height: 54px;
          padding: 0;
          margin: auto 0;
          border: none;
          background: transparent;
          cursor: pointer;

          &[type='color'] {
            margin-right: 0.5rem;

            &::-moz-color-swatch {
              border-radius: 5px;
            }
            &::-webkit-color-swatch-wrapper {
              border-radius: 5px;
              border-style: none;
            }
            // edita la forma del input color
            &::-webkit-color-swatch {
              border-radius: 5rem;
              border: 1px solid #cdcdcd;
            }
          }
        }
      }
    }
  }
  .check__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bajada {
      color: #767676;
      font-weight: 400;
    }
  }
  .desktop_icon {
    width: 1.25rem;
    margin-left: 0.5rem;
  }
  .mobile_icon {
    width: 0.9rem;
    margin-left: 0.5rem;
  }
  select {
    width: 100%;
  }
  .checks_box {
    justify-content: space-between;
    display: flex;
    margin: auto;
  }
  .btn-base {
    margin: 0;
    padding: 0.5rem;
    width: 25%;

    @media (max-width: 1600px) {
      width: 40%;
    }
    @media (max-width: 1279px) {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
  .card {
    padding: 1.5rem;
    border-color: #cdcdcd;

    &-chat {
      top: 0;
    }

    .check_time {
      color: #767676;
    }
  }
  .info_box {
    @media (max-width: 1600px) {
      margin: 1.25rem auto;
    }
  }
  .two_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;

    @media (max-width: 1600px) {
      display: block;
    }
  }
}
.cart-label-top {
  border: 1px solid #cdcdcd;
  border-bottom: 0;
  background-color: #f2f2f2;
  border-radius: 1rem 1rem 0 0;
}
.label-cart {
  padding: 10px 0 0 15px;
}
.options-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}

.delete-input {
  display: flex;
  justify-content: space-between;
}

.delete-input-option button {
  border: none;
  width: 50px;
  margin-bottom: 0rem;
  border-radius: 0 1rem 0 0;
  border-left: 1px solid #cdcdcd;
}
.suboptionMenu {
  margin-left: 3px;
}
</style>
