<template>
  <span class="icon-flag" :class="classes" v-if="phoneNumber">
    <img v-if="setSelectedFlag" :src="setSelectedFlag.url" :alt="setSelectedFlag.name">
  </span>
</template>

<script>
export default {
  props: ["phoneNumber", "classes"],

  data() {
    return {
      flags: [
        {
          name: "Chile",
          code: "56",
          url: "/img/icons/flags/1-chile.svg"
        },
        {
          name: "Brasil",
          code: "55",
          url: "/img/icons/flags/3-brasil.svg"
        },
        {
          name: "Argentina",
          code: "54",
          url: "/img/icons/flags/4-argentina.svg"
        },
        {
          name: "México",
          code: "52",
          url: "/img/icons/flags/6-mexico.svg"
        },
        {
          name: "Perú",
          code: "51",
          url: "/img/icons/flags/7-peru.svg"
        },
        {
          name: "EE.UU",
          code: "1",
          url: "/img/icons/flags/2-eeuu.svg"
        }
      ]
    }
  },
  computed: {
    setSelectedFlag() {
      let phone = this.phoneNumber;

      // valicacion si existe el numero de telefono
      if (phone) {
        let clearSymbol = 
          phone.toString().includes("+") ?
          phone.toString().replace("+", "") :
          phone.toString()
        ;
        // si incluye codigo de area
        if (phone.toString().length > 9) {
          let numberArray = clearSymbol.toString().split("", 2).join("");

          return this.flags.find(el => numberArray.toString().includes(el.code));
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-flag {
  img {
    width: 1.25rem;
  }
}
</style>