<template>
  <div>
    <div class="contenedor__VerAgente">
      <div class="datos__EditarAgentes">
        <div>
          <label for="titulo">Nombre del agente:</label>
          {{users.name}}
        </div>

        <div>
          <label for="email">Email del agente:</label>
          {{users.email}}
        </div>

        <div>
          <label for="titulo2">Bot Relacionado:</label>
          <select name="mostrarbot" v-model="id_bot">
            <option v-for="bot in bots" :value="bot.id" :key="bot.id">{{bot.bot_name}}</option>
          </select>
        </div>

        <div>
          <label for="titulo3">Rol del Agente:</label>
          {{rolAgente}}
        </div>
      </div>

      <div class="foto__EditarAgentes">
        <div>
          <div class="media align-items-center" slot="title">
            <span class="rounded-circle">
              <img alt="Image placeholder" :src="imagen" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';

export default {
  name: 'VerAgente',
  props: ['userId'],
  data() {
    return {
      bots: [],
      roles: [],
      users: [],
      rol: [],
      rolAgente: '',
      imagen: '',
      id_bot: '',
      model: {
        rol_id: '',
        foto_url: '',
      },
      error: '',
    };
  },

  watch: {
    id_bot() {
      const self = this;
      dashboard_api.get(`/role/role_bot/${self.id_bot}`).then((result) => {
        self.roles = result.data;
      });

      dashboard_api
        .get(`/userbot/user_for_bot/${self.id_bot}`)
        .then((result) => {
          self.rol = result.data;
          console.log(self.rol);

          self.rol.forEach(
            (e) => (e.user_id == self.userId ? (self.rolAgente = e.role.name) : ''),
            console.log(`${self.rolAgente} este es el rol`),
          );
        });
    },
    userId() {
      const self = this;
      // Obtener el usuario para editar
      dashboard_api
        .get(`/relief/userfind/${self.userId}`)
        .then((result) => {
          console.log(result.data);
          self.users = result.data;
          result.data.image !== null
            ? (self.imagen = result.data.image)
            : (self.imagen = 'https://i.ibb.co/88MLkwn/no-user-image-png-2.png');
        });
      // Obtener el bot el cual esta relacionado el user
      dashboard_api.get(`/bot/planuser/${self.user.id}`).then((result) => {
        self.bots = result.data;
      });
    },
  },

  computed: {
    ...mapState(['user', 'selected_bot']),

    id_users() {
      return this.$route.params.id;
    },
  },

  created() {
    const self = this;
    window.scrollTo(0, 0);
    // Obtener el usuario para editar
    dashboard_api
      .get(`/relief/userfind/${self.id_users}`)
      .then((result) => {
        self.users = result.data;
        result.data.image !== null
          ? (self.imagen = result.data.image)
          : (self.imagen = 'https://i.ibb.co/88MLkwn/no-user-image-png-2.png');
      });
    // Obtener el bot el cual esta relacionado el user
    dashboard_api.get(`/bot/planuser/${self.user.id}`).then((result) => {
      self.bots = result.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.contenedor__VerAgente {
  color: #808080;
  font-size: 14px;
  width: 510px;
  padding: 20px;
  label {
    color: #808080;
    font-size: 14px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  img {
    width: 150px;
    border-radius: 50%;
    margin: -20px 0 0 40px;
  }
  select {
    font-size: 13px;
    color: #808080;
    padding: 5px 10px;
    margin: 0px 0 10px -2px;
    border-radius: 12px;
  }
}
</style>
