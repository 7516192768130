var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.selected_bot.id,class:{
    databot_live: true,
    dlive_item3: _vm.show_client_info
  }},[_c('div',{class:{
      'item item-1': true,
      hidden: _vm.showMobileViewChat || _vm.show_client_info,
      showMobileMode: !_vm.showMobileViewChat && !_vm.show_client_info
    }},[_c('Chats',{key:_vm.sendkey,ref:"Chats",attrs:{"modal":_vm.modal,"agents":_vm.agents,"setConnect":_vm.setConnect,"agentAccount":_vm.agentAccount,"toArchive":_vm.toArchive,"selectedChat":_vm.selectedChat,"sendPushDataKey":_vm.sendPushDataKey,"toUnArchivedChat":_vm.toUnArchivedChat,"getUpdateChatDate":_vm.lastMessageData},on:{"selectedChat":_vm.getSelectedChat,"sendCheckActiveChat":function($event){_vm.checkActiveChat = $event},"sendClearViewChat":_vm.clearViewChat,"sendConnectAgent":_vm.getConnectAgent,"sendShowArchived":function($event){_vm.showArchived = $event},"sendUpdateModal":function($event){_vm.modal = $event}}})],1),_c('div',{class:{
      'item item-2 fadeItemMobile': true,
      hidden: !_vm.showMobileViewChat,
      showMobileMode: _vm.showMobileViewChat,
    }},[_c('ViewChat2',{ref:"viewchat",attrs:{"modal":_vm.modal,"agents":_vm.agents,"messages":_vm.messages,"checkActiveChat":_vm.checkActiveChat,"lastClientMessageDate":_vm.lastClientMessageDate,"selectedChat":_vm.selectedChat,"isTyping":_vm.isTyping},on:{"imTyping":_vm.imTyping,"sendConnectAgent":_vm.getConnectAgent,"sendMessage":function($event){return _vm.sendMessage($event)},"sendFile":function($event){return _vm.sendFile($event)},"sendToArchive":function($event){_vm.toArchive = !_vm.toArchive},"closeMobile":function($event){_vm.showMobileViewChat = false},"sendDeriveAgent":function($event){_vm.showDeriveAgents = $event}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_client_info),expression:"show_client_info"}],class:{
      'item item-3 fadeItemMobile': true,
      hidden: !_vm.show_client_info,
      showMobileMode: _vm.show_client_info,
    }},[_c('InfoChat2',{attrs:{"modal":_vm.modal,"selectedChat":_vm.selectedChat,"files":_vm.files,"show_client_info":_vm.show_client_info},on:{"refreshFiles":_vm.getFiles,"sendDeriveAgent":function($event){_vm.showDeriveAgents = $event},"sendUpdateModal":function($event){_vm.modal = $event}}})],1),_c('DesarchivarConversacion',{key:_vm.showArchived,attrs:{"showModal":_vm.showArchived},on:{"closeModal":function($event){_vm.showArchived = false},"updateChats":_vm.updateChats}}),_c('DerivarAgente',{attrs:{"agents":_vm.agents,"showModal":_vm.showDeriveAgents},on:{"closeModal":function($event){_vm.showDeriveAgents = false},"derivar":_vm.shuntAgent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }