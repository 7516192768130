<template>
  <Modal class="modal-2" alto="500" ancho="800" :show="show" @close="$emit('close', (search = ''))">
    <template v-if="show" slot="content">
      <section class="enviar-plantilla">
        <div class="col-lg-12 p-4">
          <h2 class="title-template">Envío de mensajes masivos</h2>
          <div class="row">
            <div class="col-lg-8">
              <p class="mb-3">
                Busca la plantilla que deseas enviar como mensaje masivo.
              </p>
            </div>
            <div class="col-lg-4">
              <input
                placeholder="Buscar plantilla"
                class="input_search-sm"
                type="search"
                v-model="search"
              />
            </div>
          </div>
          <!-- <div class="col-lg-12">
            <div class="info_box mb-4">
              <p>
                <i class="fas fa-info-circle" /> Enviar mensajes de plantilla tiene un costo asociado de USD $0,5.
              </p>
            </div>
          </div> -->
          <h2 class="title-template list-temp">Listado de plantillas</h2>
          <div v-if="contentFilter.length > 0" class="scroll_box">
            <div
              class="my-4 fade_down"
              v-for="(template, templateIndex) in contentFilter"
              :key="templateIndex"
            >
              <template v-if="template.status === 'approved'">
                <div class="row justify-content-between">
                  <div class="col-lg-9 px-0">
                    <label class="d-flex justify-content-between align-items-center"
                      >{{ template.name }}
                      <span class="text-state approved ml-2">
                        {{ template.status === "approved" ? "Aprobado" : "" }}
                      </span></label
                    >
                    <div tabindex="1" class="div_input">
                      {{ template.components.find(el => el.type === "BODY").text }}
                    </div>
                  </div>
                  <div class="col-lg-2 d-flex align-items-end pr-0">
                    <button
                      class="btn btn-base sm send-template"
                      @click="sendTemplate(values, template)"
                    >
                      <i class="fas fa-paper-plane" />Enviar
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-else>
            <div class="info_box fade_down">
              <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
              <p class="text-center">No se encontraron mensajes</p>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import dashboard_api from "@/dashboard_api.js";
import { mapState } from "vuex";

export default {
  components: {
    environment
  },
  props: ["show", "templates", "values"],
  data() {
    return {
      loading: false,
      search: ""
    };
  },
  computed: {
    ...mapState(["selected_bot", "user"]),

    contentFilter() {
      let itemSearch = this.templates;

      if (!this.search) return itemSearch;

      return itemSearch.filter(item => {
        let text = item.components.find(el => el.type === "BODY").text;

        return [item.name, text].find(field => {
          return field
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .trim()
            );
        });
      });
    }
  },
  methods: {
    sendTemplate(values, template) {
      this.loading = false;
      let html = `
          <p>¿Estás listo? Este mensaje se enviará a:
          <div class="col-lg-12 my-4">
            <div class="info_box bg_grey">
              <div class="scroll_box">
                <div class="row">
                  <div class="col-lg-6 pl-0">
                    ${values
                      .map(value => `<p class="text-left negrita">` + value.name + `</p>`)
                      .join(" ")}
                  </div>
                  <div class="col-lg-6 pr-0">
                    ${values.map(value => `<p class="text-left">` + value.phone + `</p>`).join(" ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
      // verificando si algunos leads no tienen datos dinamicos para completar plantilla
      console.log("PLANTILLA: ", template);
      console.log("LEADS: ", values);
      console.log("USER: ", this.user);

      let dangerLeads = this.checkLeadsTemplateData(values, template);

      if (dangerLeads.length > 0) {
        html += `
        <p>Los siguientes leads no cuentan con los datos suficientes para completar el mensaje de plantilla:</p>
        <div class="col-lg-12 my-4">
            <div class="info_box">
              <div class="scroll_box">
                <div class="row">
                  <div class="col-lg-6 pl-0">
                    ${dangerLeads
                      .map(value => `<p class="text-left negrita">` + value.name + `</p>`)
                      .join(" ")}
                  </div>
                  <div class="col-lg-6 pr-0">
                    ${dangerLeads
                      .map(value => `<p class="text-left">` + value.phone + `</p>`)
                      .join(" ")}
                  </div>
                </div>
              </div>
            </div>
          </div>`;
      }
      this.$swal({
        title: "Enviar mensaje de plantilla",
        html,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.isConfirmed) {
          // evitando el error e la respuesta del endpoint
          // this.$emit("sendTemplateToChat", template);
          this.$swal({
            icon: "info",
            title: "Enviando...",
            showConfirmButton: false,
            allowOutsideClick: false
          });
          this.$swal.showLoading();
          
          axios
            .post(environment.socket_url + "/whatsapp/360dialog/send-template-message/massive", {
              botId: this.selected_bot.id,
              phones: values.map(e => e.phone),
              leads: values.map(e => ({
                name: e.name,
                email: e.email,
                phone: e.phone,
                id: e.id,
                custom: e.custom,
                session_id: e.session_id
              })),
              user_id: this.user.id,
              name: template.name
            })
            .then(response => {
              // se verifica si hubo algun telefono al que no le llego la plantilla
              let failedNumbers = response.data.payload.failed.phones;
              if (failedNumbers.length > 0) {
                // fallaron algunos numeros
                this.$swal({
                  icon: "info",
                  title: "Listo, pero verifica estos números",
                  text: `⚠️ El mensaje no se pudo enviar a los siguientes números: ${failedNumbers.join(
                    ", "
                  )}`,
                  showConfirmButton: true
                });
                // se guardan los numeros fallidos en sessionStorage, para resaltarlos en CRM
                this.$emit("onFailedNumbers", failedNumbers);
                sessionStorage.setItem("failed_numbers", JSON.stringify(failedNumbers));
                this.loading = false;
              } else {
                // todo se envio correctamente
                // Enviado plantilla para mostrar en la conversacion
                this.$emit("sendTemplateToChat", template);
                this.$swal({
                  icon: "success",
                  title: "¡Mensaje enviado!",
                  timer: 3000,
                  showConfirmButton: false
                });
                this.loading = false;
                let leadIds = [];

                values.forEach(el => {
                  leadIds.push(el.id);
                });
              }
              this.unarchiveConversationsLeads(values);
            });

          this.loading = true;
        }
      });
    },
    unarchiveConversationsLeads(leads) {
      for (const lead of leads) {
        if (
          lead.channel === "Whatsapp Bot" &&
          lead.databot_lives &&
          lead.databot_lives.length > 0 &&
          lead.databot_lives[0].chat_rooms &&
          lead.databot_lives[0].chat_rooms.length > 0
        ) {
          let chatRooms = lead.databot_lives[0].chat_rooms;
          for (const chatRoom of chatRooms) {
            // es probable que haya varios chatRooms por desarchivar
            if (chatRoom.channel === "Whatsapp Bot") {
              dashboard_api.put(`/chat_rooms/${chatRoom.room_token}`, {
                archived: false
              });
            }
          }
        }
      }
    },
    checkLeadsTemplateData(leads, template) {
      /**
       * Hay constantes en el lead que tienen nombre diferente al tratarla como variable del sistema para plantilla
       * name = $lead.nombre
       * email = $lead.correo
       * phone = $lead.telefono
       * Los demás campos (dentro del campo custom, mantienen su mismo nombre como variable del sistema)
       */
      // buscando variables dinamicas usadas por mensaje plantilla
      let dynamicVariables = template.components
        .filter(el => el.custom_parameters && el.custom_parameters.length > 0)
        .reduce((acc, current) => [...acc, ...current.custom_parameters], [])
        .flat();
      let filteredLeads = leads.filter(
        lead =>
          !dynamicVariables.every(variable => {
            return (
              Object.keys(lead.custom).includes(variable.replace("$lead.", "")) ||
              (variable === "$lead.nombres" && lead.name && lead.name !== "--") ||
              (variable === "$lead.correo" && lead.email) ||
              (variable === "$lead.phone" && lead.telefono)
            );
          })
      );
      return filteredLeads;
    }
  }
};
</script>

<style lang="scss">
// for swal content
.swal2-content {
  .info_box {
    padding: 0.25rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;

    .row {
      padding: 1rem 0;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .scroll_box {
    width: 100%;
    max-height: 200px;
    padding: 0;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.enviar-plantilla {
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .modal-content {
    width: 760px;
  }
  input {
    width: 100%;
    margin: 0 0 1rem;
  }
  select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
  .btn-base {
    width: 100%;
    margin: 0;
  }
  .div_input {
    font-size: 14px;
    line-height: 1.5rem;
    overflow-y: hidden;
    width: auto;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #767676;
    border-radius: 0;
    border-bottom: 1px solid #cdcdcd;
    padding: 0.75rem 0;
    transition: 0.5s;
    margin: 0;

    &:hover {
      color: #181818;
      border-bottom: 1px solid #333;
    }
    &:focus {
      height: auto !important;
      overflow-y: auto !important;
      animation: show-navbar-dropdown 0.25s ease forwards;
      color: #767676;
      border-bottom: 1px solid #333;
    }
  }
  .scroll_box {
    max-height: 400px;
    padding-left: 14px;
    padding-right: 10px;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .text-state {
    margin: 0;
  }
  .fade_down {
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .icon {
    display: flex;
    justify-content: center;
    margin: 0 auto 1rem;
  }
}

.title-template {
  padding: 0 15px 10px 15px;
}
h2.title-template.list-temp {
  margin-bottom: -10px;
  padding-top: 10px;
}
.p-4 {
  padding: 1rem 1.5rem 1.5rem 1.5rem !important;
}
.send-template {
  padding: 0.25rem 0.25rem;
}
</style>
