<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal', false)">
    <template slot="header">
      <h3></h3>
    </template>
    <template slot="content">
      <section class="conversaciones-archivadas">
        <div class="col-lg-12">
          <label class="mb-4"><i class="fab fa-whatsapp" />Conversaciones archivadas</label>
          <div class="row justify-content-between">
            <div class="col-md-8 pl-0">
              <p>
                Puedes desarchivar las conversaciones y volver a contestar a tus usuarios de
                WhatsApp.
              </p>
            </div>
            <div class="col-md-4 d-flex justify-content-end pr-0">
              <input
                type="search"
                class="input_search-sm mx-0"
                placeholder="Buscar"
                v-model="searchChat"
              />
            </div>
          </div>
          <SpinnerLoading v-if="loading" />
          <template v-else-if="chatFilter && chatFilter.length == 0">
            <div class="col-lg-12 px-0">
              <div class="info_box bg_grey mt-5">
                <p>No tienes conversaciones archivadas</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="custom_table mt-5">
              <div class="scroll_box">
                <div
                  v-for="chat in chatFilter"
                  :key="chat.id"
                  class="row item justify-content-between align-items-center"
                >
                  <div class="col-sm-4 px-0">
                    <!-- <label class="mb-0">{{chat.databot_live.lead.name || 'Cliente'}}</label> -->
                    <label class="mb-0">{{ chat.databot_live.lead.name || "Cliente" }}</label>
                  </div>
                  <div class="col-sm-4 px-0">
                    <p class="mb-0">{{ chat.created_at }}</p>
                  </div>
                  <div class="col-sm-4 d-flex justify-content-end px-0">
                    <button class="btn btn-base sm my-0" @click="desarchivar(chat)">
                      Desarchivar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <Pagination :pages="archivedChats.pages" @getItems="getArchivedChats" />
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import environment from "@/environment";
import Pagination from "@/components/Pagination.vue";
import dashboard_api_v2 from "@/dashboard_api_v2";

export default {
  props: ["showModal", "closeModal"],

  components: {
    Pagination
  },

  data() {
    return {
      archivedChats: [],
      loading: false,
      searchChat: "",
    };
  },

  mounted() {
    this.getArchivedChats();
  },

  computed: {
    ...mapState(["selected_bot"]),

    chatFilter() {
      let itemSearch = this.archivedChats;

      if (!this.searchChat) return itemSearch;

      return itemSearch.filter(item => {
        let name = item.databot_live.lead.name || "Cliente";
        let date = item.created_at;

        return [name, date].find(field => {
          return field
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.searchChat
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .trim()
            );
        });
      });
    }
  },

  methods: {
    async getArchivedChats(page = 1) {
      this.loading = true;
      this.archivedChats = (
        await dashboard_api_v2.get(`/chat_rooms/all_archived_chats/${page}/${this.selected_bot.id}`)
        ).data.data;
      this.loading = false;
    },

    desarchivar(chat) {
      this.$swal({
        title: "¿Desarchivar conversación?",
        text: "Podrás volver a ponerte en contacto con este usuario.",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.isConfirmed) {
          this.archivedChats = this.archivedChats.filter(item => item.id != chat.id);
          this.$emit("updateChats", chat);
          console.log("archivedChats-->", this.archivedChats);

          if (this.archivedChats.length == 0) {
            this.$emit("closeModal", false);
          }
          axios
            .post(environment.socket_url + "/whatsapp/hide-prechat", {
              room: chat.room_token,
              botId: chat.bot_id,
              status: false
            })
            .then((resp) => {
              console.log("resp", resp);
            });
          this.$swal({
            toast: true,
            icon: "success",
            text: "Desarchivada",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.conversaciones-archivadas {
  padding: 0 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .custom_table {
    border: 1px solid #cdcdcd;
    border-radius: 1rem;
    background-color: #fafafa;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;

    .item {
      margin: auto 1.5rem;
      padding: 1rem 0;
      border-bottom: 1px solid #cdcdcd;

      &:last-child {
        border-bottom: 0;
      }
    }

    .scroll_box {
      max-height: 400px;
      overflow-y: scroll !important;
      flex-grow: 1;
      margin-bottom: 0;

      &::-webkit-scrollbar {
        height: 6px;
      }
      /* Color de fondo y radius esquinas del thumb */
      &::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        transition: 0.5s;
        visibility: visible;
        border-radius: 2rem;
        margin-bottom: 1rem;

        /* Cambiamos el fondo en hover */
        &:hover {
          transition: 0.5s;
          background: #cdcdcd;
        }
        /* Cambiamos el fondo en active */
        &:active {
          background-color: #cdcdcd;
        }
      }
    }
  }
  .info_box {
    text-align: center;
    font-size: 12px;
    padding: 2rem;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .xs {
    width: fit-content;
  }
}
</style>
