var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatbot"},[_c('div',{staticClass:"content-chat"},[_c('div',{staticClass:"header-chat",style:('background-color: ' + _vm.chatbot__color__principal + ';')},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.chatbot__logo)?_c('img',{staticClass:"img-perfil",style:('color: ' + _vm.chatbot__color__principal + ';'),attrs:{"src":_vm.chatbot__logo,"alt":_vm.chatbot__nombre}}):_c('img',{staticClass:"img-perfil",style:('color: ' + _vm.chatbot__color__principal + ';'),attrs:{"src":'/img/brand/0-default.svg',"alt":_vm.chatbot__nombre}}),_c('div',[_c('label',{staticClass:"mb-0",style:('color: ' + _vm.chatbot__color__secundario + ';')},[_vm._v(_vm._s(_vm.chatbot__nombre))]),_c('p',{staticClass:"text_xs"},[_vm._v("Disponible ahora")])])]),(_vm.accessibility)?_c('div',{staticClass:"btn_box"},[_c('span',{staticClass:"btn-dash btn-dash-sm mr-2"},[_vm._v("+A")]),_c('span',{staticClass:"btn-dash btn-dash-sm"},[_vm._v("-A")])]):_vm._e()]),_c('div',{staticClass:"mensajes"},[(!_vm.chatbot_menu)?[_c('div',{staticClass:"mensajes-left"},[_c('label',[_vm._v(_vm._s(_vm.chatbot__nombre))]),_vm._m(0)]),_c('div',{staticClass:"mensajes-right"},[_c('label',[_vm._v("Tu")]),_c('div',{staticClass:"mensajes-right__item",style:('background-color: ' + _vm.chatbot__color__principal + ';')},[_c('p',{style:('color: ' + _vm.chatbot__color__secundario + ';')},[_vm._v(" Necesito cotizar un chatbot para mi sitio web ")]),_c('p',{staticClass:"text_xs"},[_vm._v("22/06/2022")])])]),_c('div',{staticClass:"mensajes-left"},[_c('label',[_vm._v(_vm._s(_vm.chatbot__nombre))]),_vm._m(1)])]:[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"mensajes-left"},[(
                _vm.welcomes &&
                  _vm.welcomes.length == 0 &&
                  _vm.options &&
                  _vm.options.length == 0
              )?_c('Loading'):[_c('label',[_vm._v(_vm._s(_vm.chatbot__nombre))]),(_vm.welcomes && _vm.welcomes.length > 0)?_c('div',_vm._l((_vm.welcomes),function(welcome){return _c('div',{key:welcome.id,staticClass:"mensajes-left__item"},[_vm._v(" "+_vm._s(welcome.text)+" "),_c('p',{staticClass:"text_xs"},[_vm._v("Hoy a las 11:23")])])}),0):_vm._e(),_c('div',{staticClass:"mensajes-left__item p-0"},[_c('div',{staticClass:"menu"},_vm._l((_vm.options),function(option,index){return _c('p',{key:option.id,staticClass:"menu-item mb-0"},[_vm._v(" "+_vm._s(option.label || _vm.placeholderOptions[index])+" ")])}),0)])]],2)])]],2),_vm._m(2)]),_c('div',{staticClass:"burbuja"},[(!_vm.bubble)?_c('div',{staticClass:"burbuja__icono",style:('background-color: ' + _vm.chatbot__color__principal + ';')},[_c('img',{attrs:{"src":"https://cdn.discordapp.com/attachments/700052004877238285/745778899757170808/chat-databot-2.png","alt":_vm.chatbot__nombre}})]):_c('img',{staticClass:"img_bubble",attrs:{"src":_vm.chatbot__logo ? _vm.chatbot__logo : '/img/brand/0-default.svg',"alt":_vm.chatbot__nombre}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mensajes-left__item"},[_c('p',[_vm._v(" Bienvenido a Databot, estoy disponible, cuéntame en que te puedo ayudar? ")]),_c('p',{staticClass:"text_xs"},[_vm._v("22/06/2022")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mensajes-left__item"},[_c('p',[_vm._v(" ¡Genial! con gusto te ayudaré con esa información. Me gustaría conocerte un poco más. ¿Cuál es tu nombre? ")]),_c('p',{staticClass:"text_xs"},[_vm._v("22/06/2022")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credits"},[_c('img',{staticClass:"databot-iso",attrs:{"src":"/img/icons/1-databot-icon.svg","alt":"logo databot"}}),_c('span',[_vm._v("Usamos Databot")])])
}]

export { render, staticRenderFns }