<template>
  <section
    :key="selected_bot.id"
    :class="{
      databot_live: true,
      dlive_item3: show_client_info
    }"
  >
    <div
      :class="{
        'item item-1': true,
        hidden: showMobileViewChat || show_client_info,
        showMobileMode: !showMobileViewChat && !show_client_info
      }"
    >
      <!-- @deletePrechat="deletePrechat($event.room_token)" -->
      <Chats
        ref="Chats"
        :modal="modal"
        :agents="agents"
        :key="sendkey"
        :setConnect="setConnect"
        :agentAccount="agentAccount"
        :toArchive="toArchive"
        :selectedChat="selectedChat"
        :sendPushDataKey="sendPushDataKey"
        :toUnArchivedChat="toUnArchivedChat"
        :getUpdateChatDate="lastMessageData"
        @selectedChat="getSelectedChat"
        @sendCheckActiveChat="checkActiveChat = $event"
        @sendClearViewChat="clearViewChat"
        @sendConnectAgent="getConnectAgent"
        @sendShowArchived="showArchived = $event"
        @sendUpdateModal="modal = $event"
      />
    </div>
    <div
      :class="{
        'item item-2 fadeItemMobile': true,
        hidden: !showMobileViewChat,
        showMobileMode: showMobileViewChat,
      }"
    >
      <ViewChat2
        ref="viewchat"
        :modal="modal"
        :agents="agents"
        :messages="messages"
        :checkActiveChat="checkActiveChat"
        :lastClientMessageDate="lastClientMessageDate"
        :selectedChat="selectedChat"
        :isTyping="isTyping"
        @imTyping="imTyping"
        @sendConnectAgent="getConnectAgent"
        @sendMessage="sendMessage($event)"
        @sendFile="sendFile($event)"
        @sendToArchive="toArchive = !toArchive"
        @closeMobile="showMobileViewChat = false"
        @sendDeriveAgent="showDeriveAgents = $event"
      />
    </div>
    <div
      v-show="show_client_info"
      :class="{
        'item item-3 fadeItemMobile': true,
        hidden: !show_client_info,
        showMobileMode: show_client_info,
      }"
    >
      <InfoChat2
        :modal="modal"
        :selectedChat="selectedChat"
        :files="files"
        :show_client_info="show_client_info"
        @refreshFiles="getFiles"
        @sendDeriveAgent="showDeriveAgents = $event"
        @sendUpdateModal="modal = $event"
      />
    </div>
    <!-- Modals -->
    <DesarchivarConversacion
      :key="showArchived"
      :showModal="showArchived"
      @closeModal="showArchived = false"
      @updateChats="updateChats"
    />
    <DerivarAgente
      :agents="agents"
      :showModal="showDeriveAgents"
      @closeModal="showDeriveAgents = false"
      @derivar="shuntAgent"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FiltersDatabotLive from '@/components/custom/FiltersDatabotLive';
import Chats from '@/components/Databot-live/chat_cards';
import ViewChat2 from '@/components/Databot-live/view_chat';
import InfoChat2 from '@/components/Databot-live/info_chat';
import dashboard_api from '@/dashboard_api.js';
import dashboard_api_v2 from '@/dashboard_api_v2.js';
import {} from 'buefy';
import AdjuntarArchivos from '@/components/AdjuntarArchivos';
import { whatsappTimeRemaining, isImage, scrollBottom } from '@/utils/utils.js';
import filtersDatabotLive from '@/utils/filtersDatabotLive.js';
import socket from '@/plugins/sockets';
import DesarchivarConversacion from '@/components/DesarchivarConversacion.vue';
import DerivarAgente from '@/components/DerivarAgente';
import moment from "moment-strftime";

export default {
  components: {
    AdjuntarArchivos,
    Chats,
    ViewChat2,
    InfoChat2,
    FiltersDatabotLive,
    DesarchivarConversacion,
    DerivarAgente
  },
  data() {
    return {
      typing: false, // ?
      user_chat: 'Agente',
      modal: {},
      clientsTyping: [],
      lastClientMessageDate: null,
      customFilters: {},
      selectedFilters: {},
      isTyping: false,
      checkActiveChat: null,
      messages: [],
      files: null,
      showMobileViewChat: false,
      setConnect: null,
      agents: [],
      toArchive: false,
      showArchived: false,
      showDeriveAgents: false,
      file_formats: ['.png', '.jpg', '.jpeg', '.pdf', 'xlsx', '.xls'],
      sendPushDataKey: false,
      lastMessageData: {},
      agentAccount: {},
      toUnArchivedChat: {},
      sendkey: false
    };
  },
  computed: {
    ...mapState([
      'user',
      "roomToken",
      'selected_bot',
      'show_client_info',
      'push_data',
      'selectedChat',
      'activeChatRooms'
    ]),
  },

  async mounted() {
    window.scrollTo(0, 0);
    await this.getAgents();
    // Recibe el mensaje desde el cliente y lo agrega al chat de agente
    // if (this.$store.state.isSocketInitialized) return; // validacion no repetir listener
    socket.on('IG_DELETED_MESSAGE', (data) => {
      const { mid } = data;
      const index = this.messages.findIndex(el => el.mid == mid);
      if (index > -1) {
        this.messages[index].text = "Este mensaje ha sido eliminado";
      }
    });
    socket.on('CHAT_MESSAGE_AGENTE', (data) => {
      console.log("CHAT_MESSAGE_AGENTE->", data);
      const { message, room } = data;
      this.addMessageToChat(message, room);
      // Captura el socket event de CHAT_MESSAGE_AGENTE
      this.lastMessageData = data;
      // actualizando la fecha del ultimo mensaje recibido para contador Whatsapp
      if (this.selectedChat && (this.selectedChat.channel === 'Whatsapp Bot' || this.selectedChat.channel === 'instagram') && this.selectedChat.room_token == data.room ) {
        // console.log("here 2->");
        this.lastClientMessageDate = new Date();
      }
    });

    socket.on('CLIENT_TYPING', (data) => {
      // console.log("CLIENT_TYPING", data);

      if (data.typing_status) {
        // agregando client typing
        this.clientsTyping.push(data.room);
      } else {
        // borrando client typing
        const index = this.clientsTyping.findIndex((el) => el == data.room);
        this.clientsTyping.splice(index, 1);
      }
      if (this.selectedChat) {
        // Limpia y/o mantiene is typing dinámico - desaparezca de chat seleccionado sin volver a seleccionar
        data.room == this.selectedChat.room_token ? this.isTyping = data.typing_status : this.isTyping;
      }
    });
  },

  methods: {
    ...mapMutations([
      "setNewMessage",
      "setSelectedChat",
      "setActiveChatRooms",
      "addActiveChatTokens",
      "addActiveChatRoom",
      "setShowClientInfo",
      "setAgentAccount",
      "setCurrentRoomToken"
    ]),

    updateChats(chat) {
      this.toUnArchivedChat = chat;
      this.sendPushDataKey = !this.sendPushDataKey;
    },
    saveNewNote(title, body) {
      dashboard_api
        .post("/lead_notes", {
          title: `Para ${title}`,
          body,
          lead_id: this.selectedChat.databot_live.lead_id,
        })
        .then((result) => {
          console.log("nota creada", result);
        });
    },

    shuntAgent(agent, transfered, transfer_reason, selectedArea) {
      console.log("1 selected_agent->", agent);
      console.log("2 transfered->", transfered);
      console.log("3 comment->", transfer_reason);
      console.log("4 selectedArea->", selectedArea);
      this.$swal.showLoading();

      dashboard_api.post('/chat_rooms/save_agent_history', {
        id: this.roomToken,
        agent_id: agent.user_id
      });

      this.selectedChat.category = selectedArea;
      this.selectedChat.agent_id = agent.user_id;
      this.selectedChat.agent_history.push(this.user.id);
      this.setSelectedChat(this.selectedChat);

      dashboard_api
        .put(`/chat_rooms/${this.roomToken}`, {
          chat_room: {
            category: selectedArea,
            client_state: 'tomado',
            status: true,
            agent_id: agent.user_id
          },
        })
        .then((resp) => {
          console.log("resp->", resp.data);
          this.messages = [];

          dashboard_api
            .put(`/relief/upagent/${resp.data.databot_live.lead_id}`, {
              agent: agent.user.name,
            })
            .then((result) => {
              console.log('Cambio de Agente Asignado', result.data);

              // Actualizando cards              
              // let test = this.activeChatRooms.filter(el => {el.agent_id != this.user.id && el.room_token == this.roomToken});
              // this.setActiveChatRooms(test);
            })
            .catch((err) => {
              console.log("err->", err);
            });

          // Generar nota con el comentario de transferencia
          this.saveNewNote(agent.user.name, transfer_reason);
          // Agente transferido
          this.saveAgentHistory(agent, transfered, transfer_reason);
          this.$swal({
            icon: "success",
            title: "Listo",
            timer: 1000,
            showConfirmButton: false
          });
        })
        .catch((error) => console.log(error));
    },

    saveAgentHistory(agent, transfered, transfer_reason) {
      dashboard_api_v2
        .get(`/databot_lives/${this.selectedChat.databot_live_id}`)
        .then(databotLive => {
          dashboard_api
            .post('/agent_activities', {
              agent_activity: {
                agent_name: agent.user.name,
                agent_id: agent.user_id,
                conversation_id: databotLive.data.conversation_id,
                duration: moment(new Date()).diff(moment(this.selectedChat.created_at), 'days').toString(),
                transfered,
                transfer_reason,
                transfered_by: this.user.name
              }
            })
            .then((resp) => {
              // console.log("resp->", resp);
              // Actualizar conversación derivada 
              socket.emit('JOIN_ROOM', {
                room: this.roomToken,
                status: '',
                id: this.selectedChat.databot_live_id,
                botId: this.selectedChat.bot_id,
                payload: this.selectedChat,
              });
              socket.emit('STATUS', {
                room: this.roomToken,
                status: true,
                user: agent.user,
                botId: this.selected_bot.id,
                channel: this.modal.channel,
                sender_id: this.modal.sender_id,
                options: {
                  botPhone: this.modal.custom
                    ? this.modal.custom.special_bot_phone
                    : null,
                  userPhone: this.modal.phone,
                },
              });
              // Set selected chat null
              this.setSelectedChat(null);
              // Cierra la ventana de info si es que esta abierta
              this.setShowClientInfo(false);
              // to reset selected room token
              this.setCurrentRoomToken(null);
              // Actualiza las cards en componente Chat cards
              this.sendkey = !this.sendkey;
            })
            .catch((err) => console.log("err", err));
        }).catch(error => console.log(error));
    },

    async getAgents() {
      this.agents = (await dashboard_api.get(`/userbot/getallAgent/${this.selected_bot.id}`)).data;
      // console.log("agents 1->", this.agents);

      this.agentAccount = this.agents.find(el => el.user_id == this.user.id);
      this.setAgentAccount(this.agentAccount);
      console.log("agentAccount->", this.agentAccount);
    },

    getConnectAgent(val) {
      this.setConnect = val;

      // Necesario para volver a utilizar setConnect
      setTimeout(() => {
        this.setConnect = null;
      }, 1000);
    },
    async getFiles(chat) {
      if (chat.databot_live) {
        this.files = (await dashboard_api.get(`/conversations/${chat.databot_live.conversation_id}`)).data.conversation_files;
        // console.log("files->", this.files);
      }
    },
    async getMessages(chat) {
      if (chat.databot_live) {
        this.messages = (await dashboard_api.get(`/databot_live_logs/getall/${chat.databot_live.id}`)).data;
        // console.log("messages->", this.messages);
      }
      this.getFiles(chat);

      this.$nextTick(() => {
        // scroll to end of chat withouth animation
        const objDiv = document.getElementById('chat-container'); // es el id del contenedor del chat
        objDiv.scrollTop = objDiv.scrollHeight;
      });
      for (const log in this.messages) {
        const data = this.messages[log];
        // Ultimo mensaje del cliente en Whatsapp
        if (data.from === 'Cliente') {
          // console.log("here 1->", data);
          this.lastClientMessageDate = data.updated_at;
        }
      }
    },

    async getSelectedChat(chat) {
      this.setSelectedChat(chat);
      this.showMobileViewChat = true;
      console.log("selectedChat->", this.selectedChat);
      await this.getModal(chat);

      // Limpia y/o mantiene is typing al cambiar de chat
      this.clientsTyping.includes(chat.room_token) ? this.isTyping = true : this.isTyping = false;

      if (chat.room_token == this.roomToken) {
        this.setNewMessage(false);
      }
    },
    // Persiste y envia el archivo
    // TODO probablemente esto se debe mover a fileUpload.vue
    sendFile(file) {
      if (this.checkActiveChat) {
        this.persistChatbotLiveLog(this.user_chat, file.url);
        socket.emit('SEND_CHAT_MESSAGE_TO_CLIENTE', {
          from: 'Agente',
          room: this.roomToken,
          message: file.url,
          botId: this.selected_bot.id,
          sender_id: this.modal.sender_id,
          options: {
            botPhone: this.modal.custom
              ? this.modal.custom.special_bot_phone
              : null,
            userPhone: this.modal.phone,
          },
        });
        this.addMessageToChat(file.url, this.roomToken);
      }
    },

    imTyping(message) {
      // el primer if hace que se elimine el gift de escribiendo si es que no hay mensajes escribiendose
      if (message.length === 0 && this.typing) {
        this.typing = false;
        return this.typingEmit();
      }
      if (!this.typing) {
        this.typing = true;
        this.typingEmit();
      }
    },

    typingEmit() {
      socket.emit('IM_TYPING', {
        name: 'agente',
        typing_status: this.typing,
        room: this.roomToken,
      });
    },

    // Recupera la info del lead en base a su id
    async getModal(chat) {
      if (chat.databot_live) {
        this.modal = (
          await dashboard_api.get(`/relief/findlead/${chat.databot_live.lead.id}`)
          ).data;
        this.modal.category = chat.category;
        console.log("modal->", this.modal);
      }
    },

    persistChatbotLiveLog(from, message) {
      console.log("from->", from);

      let message_type = "text";
      let payload = {};

      // recorro para validar contador de archivos
      this.file_formats.forEach((el) => {
        if (message.includes(el)) {
          message_type = "image";
          payload.url = message;
        }
      });

      dashboard_api.post('/databot_live_logs', {
        databot_live_log: {
          from,
          text: message,
          databot_live_id: this.selectedChat.databot_live_id,
          message_type,
          payload: {
            url: message_type == "image" ? message : ""
          }
        },
      });
    },

    // Envía el mensaje hacia el sevidor de chat
    sendMessage(message) {
      const self = this;
      console.log("message->", message);

      const formattedMessage = {
        text: message,
        message_type: 'text',
        from: self.user_chat,
        payload: {
          url: ''
        }
      };
      // recorro para validar contador de archivos
      self.file_formats.forEach((el) => {
        if (message && message.includes(el)) {
          formattedMessage.message_type = "image";
          formattedMessage.payload.url = message;
        }
      });

      if (self.checkActiveChat) {
        self.typing = false;
        self.typingEmit();
        // prevent store whatsapp messages in frontend, only in backend
        if (self.selectedChat.channel !== 'Whatsapp Bot') {
          self.persistChatbotLiveLog(self.user_chat, message);
        }
        console.log("send message roomToken-->", self.roomToken);
        socket.emit('SEND_CHAT_MESSAGE_TO_CLIENTE', {
          from: 'Agente',
          room: self.roomToken,
          message,
          channel: self.selectedChat.channel, // whatsapp messenger telegram etc
          botId: self.selected_bot.id,
          sender_id: self.modal.sender_id,
          // alguna data opcional
          options: {
            botPhone: self.modal.custom
              ? self.modal.custom.special_bot_phone
              : null,
            userPhone: self.modal.phone,
          },
          hasToStoreBack: self.selectedChat.channel === 'Whatsapp Bot'
        });
        self.addMessageToChat(formattedMessage, self.roomToken);
      } else {
        // Actualizar conversacion con mensaje de plantilla enviado
        formattedMessage.from = "Watson";
        self.messages.push(formattedMessage);
        // Guardar en bd mensaje de plantilla enviado
        dashboard_api.post('/databot_live_logs', {
          from: "Watson",
          text: message,
          databot_live_id: self.selectedChat.databot_live_id,
          message_type: "text",
          payload: {
            url: ""
          }
        });

        self.$nextTick(() => {
          scrollBottom();
        });
      }
    },

    addMessageToChat(message, room) {
      if (this.roomToken === room) {
        message.text = this.parseWhatsappStyles(message.text);
        if (message.message_type == "image") {
          this.files.push(message.payload.url);
        }
        this.messages.push(message);

        this.$nextTick(() => {
          scrollBottom();
        });
      }
    },
    /**
     * @description Este método filtra los prechats si el bot tiene activo filtros personalizados
     */
    filterChats(chats) {
      let fields = Object.keys(this.selectedFilters);
      let hasFieldValues = fields.some(
        (field) => this.selectedFilters[field].length > 0,
      );
      return fields.length > 0 && hasFieldValues
        ? chats.filter((chat) => {
            let satisfy = false;
            let satisfies = []; // todos deben ser true
            for (const field of fields) {
              let filterIndex = this.customFilters.findIndex(
                (el) => el.name === field,
              );
              if (
                (this.selectedFilters[field].includes(
                  chat.databot_live.lead.custom[field],
                ) ||
                  this.selectedFilters[field].some(
                    (el) =>
                      el && el.includes(chat.databot_live.lead.custom[field]),
                  )) &&
                chat.databot_live.lead.custom[field]
              ) {
                satisfies.push(true);
              } else if (
                filterIndex > -1 &&
                this.customFilters[filterIndex].aux_names &&
                this.customFilters[filterIndex].aux_names.length > 0 &&
                this.selectedFilters[field].some((el) =>
                  this.customFilters[filterIndex].aux_names.some((aux_name) => {
                    // console.log("COMPARANDO: ", el, aux_name);
                    return (
                      el &&
                      String(el)
                        .toLowerCase()
                        .includes(
                          String(
                            chat.databot_live.lead.custom[aux_name],
                          ).toLowerCase(),
                        )
                    );
                  }),
                )
              ) {
                satisfies.push(true);
              } else if (
                this.selectedFilters[field].includes(undefined) &&
                !chat.databot_live.lead.custom[field] &&
                this.customFilters[filterIndex].aux_names &&
                this.customFilters[filterIndex].aux_names.every(
                  (el) => !chat.databot_live.lead.custom[el],
                )
              ) {
                satisfies.push(true);
              } else if (
                this.selectedFilters[field].includes(undefined) &&
                !chat.databot_live.lead.custom[field] &&
                !this.customFilters[filterIndex].aux_names
              ) {
                satisfies.push(true);
              } else if (this.selectedFilters[field].length > 0) {
                satisfies.push(false);
              }
            }
            if (satisfies.every((el) => el) && satisfies.length > 0) {
              satisfy = true;
            } else {
              satisfy = false;
            }
            satisfies = [];
            return satisfy;
          })
        : chats;
    },

    /**
     * @description Limpia la vista activa de conversacion en dblive
     * @params token -> token que asocia tarjeta - vista conversacion
     */
    clearViewChat(token) {
      this.modal = {
        id: null,
        name: '',
        email: '',
        phone: '',
        contact_time: '',
        question: '',
        estado: '',
        agent: '',
        rut: '',
        token: token || null, // este campo es para identificar tarjetas y vistas de chat
      };
      this.setSelectedChat(null);
      this.messages = [];
    },

    parseWhatsappStyles(template) {
      // solo parsear si no es link
      const regex = /\*(.*?)\*/g;
      const italic = /_(.*?)_/g;
      return template.includes('http')
        ? template
        : template
            .replace(regex, '<strong>$1</strong>')
            .replace(italic, '<i>$1</i>');
    },
  },

  watch: {
    async push_data(val) {
      // console.log("comentado here");
      if (val.room_token == this.roomToken) {
        await this.getModal(val.payload);
      }
    },
    show_client_info(val) {
      !val ? this.showMobileViewChat = true : this.showMobileViewChat = false;
    },
    async selectedChat(val) {
      if (val && val.id) {
        this.getMessages(val);
      }
    },
  },

  beforeDestroy() {
    socket.removeListener('CHAT_MESSAGE_AGENTE');
  },
};
</script>

<style lang="scss" scoped>
.databot_live {
  position: relative;
  display: grid;
  grid-template-columns: 460px calc(100% - 460px);
  background-color: #fff;
  max-height: 100%;
  overflow: hidden;
  height: calc(100vh - 80px);

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .item-1 {
    position: inherit;
    padding: 1.5rem 1rem;
    border-right: 1px solid #e8e8e8;
    background-color: #fff;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;
    z-index: 1;

    @media (max-width: 1600px) {
      padding: 1.5rem .25rem;
    }
    @media (max-width: 1279px) {
      padding: 1.5rem 1rem;
    }
    @media (max-width: 1024px) {
      border-right: 0;
    }
  }
  .item-2 {
    position: inherit;
    padding: 0;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;

    @media (max-width: 1279px) {
      z-index: 2;
    }
    @media (max-width: 1024px) {
      border-right: 0;
    }
  }
  .item-3 {
    position: inherit;
    padding: 1.5rem 0;

    @media (max-width: 1600px) {
      padding: 1.5rem .25rem;
    }
    @media (max-width: 1279px) {
      z-index: 3;
    }
  }
  .fadeItemMobile {
    animation-name: test;
    animation-duration: .25s;
  }
  .fadeLeaveItemMobile {
    animation-name: test2;
    animation-duration: .25s;
  }
  .showMobileMode {
    @media (max-width: 1279px) {
      display: inline-block !important;
    }
  }
  .hidden {
    @media (max-width: 1279px) {
      display: none !important;
    }
  }
}
.dlive_item3 {
  grid-template-columns: 460px 2fr 460px;

  @media (max-width: 1600px){
    grid-template-columns: 380px 2fr 380px;
  }
  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
@keyframes test {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes test2 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
</style>