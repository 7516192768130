<template>
  <div class="container" />
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState(["user", "selected_bot"]),
  },

  created() {
    const self = this;

    dashboard_api.get(`/relief/validationqvo/${self.user.id}/${self.selected_bot.id}`).then(result => {
      // console.log('result ->', result);
      if (!result.data) {
        dashboard_api
          .get(`/userbot/role_for_user_bot/${this.user.id}/${self.selected_bot.id}`)
          .then(response => {
            console.log('response ->', response);
            if (response.data) {
              if (!response.data.role.questions) self.redirectToDash();
            } else {
              self.redirectToDash();
            }
          });
      }
    });
  },

  methods: {
    redirectToDash() {
      this.$swal({
        icon: "error",
        title: "lo sentimos, pero no tienes permiso ver esta página",
        showConfirmButton: false,
      });

      location.replace("/dashboard");
    }
  }
};
</script>
