<template>
  <Modal
    class="modal-2"
    ancho="700"
    :show="show"
    @close="$emit('close')"
  >
    <template v-if="show" slot="content">
      <section class="asociar-agente">
        <div class="row">
          <div class="col-md-6 pl-0 pr-2">
            <p v-if="error == 'empty_mail'" class="text_error">Ingresa el correo</p>
            <p v-else-if="error == 'invalid_mail'" class="text_error">Ingresa un correo válido</p>
            <label v-else>Correo del agente a asociar</label>
            <input
              :class="{ input_error:
                error == 'empty_mail' ||
                error == 'invalid_mail'
              }"
              type="email"
              placeholder="nombre-agente@empresa.cl"
              v-model="model.email"
              required
            />
          </div>
          <div class="col-md-6 pr-0 pl-2">
            <p v-if="error == 'empty_rol'" class="text_error">Selecciona el Rol</p>
            <label v-else>Rol en {{ selected_bot.bot_name}}</label>
            <select
              v-if="selected_bot.id != 0"
              :class="{ input_error: error == 'empty_rol' }"
              name="mostrarrole"
              v-model="model.rol_id"
            >
              <option value disabled>Seleccionar</option>
              <option
                v-for="role in roles"
                :value="role.id"
                :key="role.id"
              >{{ role.name }}</option>
            </select>
          </div>
        </div>
        <p class="text_sm">También puedes crear un nuevo agente <router-link to="/agente/nuevo">aquí</router-link></p>
        <div class="row justify-content-center mt-3">
          <button
            class="btn btn-base"
            @click="saveUserBot"
            >Asociar agente
          </button>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";

export default {
  name: "AsociarAgentes",
  props: ['show'],

  data() {
    return {
      roles: [],
      model: {
        rol_id: "",
        email: "",
      },
      error: "",
    };
  },

  methods: {
    async getRols(bot_id) {
      this.roles = (await dashboard_api.get(`/role/role_bot/${bot_id}`)).data;

      // console.log('rol->', this.roles);
    },

    saveUserBot() {
      const self = this;
      self.error = "";

      if (self.model.email === "") {
        self.error = "empty_mail";
      } else if (!self.model.email.includes('@')) {
        self.error = "invalid_mail";
      } else if (self.model.rol_id === "") {
        self.error = "empty_rol";
      } else {
        self.error = "";

        self.$swal({
          icon: "info",
          title: "Estamos relacionando el agente...",
          showConfirmButton: false,
          allowOutsideClick: false
        });
        self.$swal.showLoading();

        dashboard_api
          .post("/userbot/userbotnew", {
            email: self.model.email,
            bot_id: self.selected_bot.id,
            role_id: self.model.rol_id,
          })
          .then((result) => {
            if (result.status == 201) {
              self.$swal({
                icon: "success",
                title: "Agente relacionado correctamente",
                showConfirmButton: false,
                timer: 1000
              });

              self.$emit('addAgentTableIndex', result.data);
            }
            self.model.email = "";
            self.model.rol_id = "";
            // self.loading = false;
          })
          .catch((err) => {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error",
            });
          });
      }
    },
  },
  watch: {
    selected_bot(val) {
      this.getRols(val.id);
    },
  },
  computed: {
    ...mapState(["user", "selected_bot"]),
  },

  created() {
    window.scrollTo(0, 0);
    this.getRols(this.selected_bot.id);
  },
};
</script>

<style lang="scss" scoped>
.asociar-agente {
  text-align: left;
  padding: 2rem;

  select {
    width: 100%;
  }
  .pl-2, .pr-2 {
    @media (max-width: 960px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
